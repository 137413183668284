import React from "react"
import Layout from "../components/Layout"
import SideNav from "../components/SideNav/SideNav"
import SideNavLink from "../components/SideNav/SideNavLink"
import { graphql, useStaticQuery } from "gatsby"

const CommitteeLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query CommitteeLayoutQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/(committees)/" } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="flex flex-col min-h-screen-nav md:flex-row">
        <SideNav>
          <h3 className="px-6 pb-4 mt-10 text-2xl font-extrabold text-gray-700 border-b border-gray-300">
            Committees
          </h3>
          {data.allMdx.edges.map(edge => (
            <SideNavLink
              to={`/committees/${
                edge.node.frontmatter.slug === "all"
                  ? ""
                  : edge.node.frontmatter.slug
              }`}
              key={edge.node.id}
            >
              {edge.node.frontmatter.title}
            </SideNavLink>
          ))}
        </SideNav>
        <div className="self-stretch flex-1 bg-gray-100">
          <div className="max-w-4xl mx-auto">
            <div className="p-5 rounded-xl lg:m-10 md:p-10 lg:bg-white lg:shadow-md">
              {children}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CommitteeLayout
