import React from "react"
import { graphql, Link } from "gatsby"
import CommitteeLayout from "../components/CommitteeLayout"
import SEO from "../components/SEO"
import Markdown from "../components/Markdown"

const Committee = props => {
  const {
    data, // this prop will be injected by the GraphQL query below.
  } = props

  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx
  return (
    <CommitteeLayout>
      {frontmatter.slug !== "all" && (
        <Link
          to="/committees"
          className="block py-2 mb-4 text-blue-600 underline" // removed md:hidden
        >
          Back to All Committees
        </Link>
      )}

      <SEO title={frontmatter.title} />

      <h1 className="mb-2 text-4xl font-bold border-b-2">
        {frontmatter.title}
      </h1>
      <Markdown content={body} className="markdown-section" />
    </CommitteeLayout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        slug
      }
    }
  }
`

export default Committee
